import React, { Component } from "react";
import styled from "styled-components";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import Popup from "reactjs-popup";
import CloseIcon from "@material-ui/icons/Close";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import Paper from "@material-ui/core/Paper";
import { confirmAlert } from "react-confirm-alert"; // Import

import { SubMenu } from "./submenu";

import { UserService } from "../../service/user.service";
import { SlotService } from "../../service/slot.service";

import { ConvertDate } from "../../utility/help";


interface Props {
  user : any;
  handleClose: () => any;
}


interface State {
  notices: any;
  inBalance : number;

  pass1: string;
  pass2: string;
}

export class Edit extends Component<Props, State> {
  userService = new UserService();
  slotService = new SlotService();

  constructor(props: Props) {
    super(props);
    this.state = { 
      notices: [],
      inBalance : 0,
      pass1: "",
      pass2: "",
    };

  }

  componentDidMount() {
  }

  
  handleExchangeToPassword = () => {
    if (this.state.pass1.length < 4 || this.state.pass1.length > 20) {
      confirmAlert({
        title: "회원정보",
        message: "비밀번호 입력 또는 4자리 이상 20자리 이하로 작성해주세요",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    if (this.state.pass1 !== this.state.pass2) {
      confirmAlert({
        title: "회원정보",
        message: "비밀번호정보가 동일해야합니다 ",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    this.userService.user_exchange_to_pass(this.state.pass1).then((s: any) => {
      if (s.status === "success") {
        confirmAlert({
          title: "회원정보",
          message: "비밀번호정보가 정상적으로 변경되었습니다. ",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                this.props.handleClose();
              },
            },
          ],
        });
        return;
      } else {
        confirmAlert({
          title: "회원정보",
          message:
            "알수없는 예러가 발생되습니다 지속적인 문제가 발생되면 문의 부탁드림니다. ",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
        return;
      }
    });
  };




  render() {
    let notices = this.state.notices;
    return (
      <Popup
        // key={`main_popup_note_${pop.idx}`}
        open={true}
        contentStyle={{
            zIndex: 99,
            background: "#000",
            border: "none",
            width: "none",
        }}
        onClose={() => {}}
      >
        {(close) => (
           <div  id="fade_2"  className="slideDown popup_none popup_content" data-popup-initialized="true" aria-hidden="false" role="dialog" style={{opacity: 1, visibility: 'visible', display: 'inline-block', outline: 'none', transition: 'all 0.3s ease 0s', textAlign: 'left', position: 'relative', verticalAlign: 'middle', overflowY : 'auto', height : '100%'}}>
           <div className="popup_wrap">
                    <div className="close_box">
                        <a href="#" className="fade_1_close"  onClick={()=>{this.props.handleClose()}}><img src="/web/images/popup_close.png" /></a>
                    </div>
                    <div className="popupbox">
       
    
    
                        <div id="popuptab_cont4" className="popuptab_cont">
                            <div className="title1">
                            마이페이지
                            </div>
                            <div className="contents_in">
    
                                <table  className="write_title_top"  style={{width:'100%'}}>
                                    <tbody>
                                      <tr>
                                        <td className="write_title">회원아이디	</td>
                                        <td className="write_basic">
                                          {
                                            this.props && this.props.user && <input className="input1" style={{width:'200px'}} value={this.props.user.id} readOnly />
                                          }
                                          </td>
                                      </tr>
    
                                      <tr>
                                        <td className="write_title">예금주	</td>
                                        <td className="write_basic">
                                          {
                                            this.props && this.props.user && <input className="input1" style={{width:'200px'}} value={this.props.user.bankowner} readOnly />
                                          }
                                          </td>
                                      </tr>

                                      <tr>
                                        <td className="write_title">은행명	</td>
                                        <td className="write_basic">
                                          {
                                            this.props && this.props.user && <input className="input1" style={{width:'200px'}} value={this.props.user.bankname} readOnly />
                                          }
                                          </td>
                                      </tr>

                                      <tr>
                                        <td className="write_title">계좌번호	</td>
                                        <td className="write_basic">
                                          {
                                            this.props && this.props.user && <input className="input1" style={{width:'200px'}} value={this.props.user.banknum} readOnly />
                                          }
                                          </td>
                                      </tr>
                                      <tr>
                                        <td className="write_title" style={{padding : '20px'}}></td>
                                        <td className="write_basic" style={{padding : '20px'}}></td>
                                      </tr>


                                      <tr>
                                        <td className="write_title">새 비밀번호</td>
                                        <td className="write_basic">
                                          {
                                            this.props && this.props.user && <input className="input1" style={{width:'200px'}} 
                                            
                                            value={this.state.pass1}
                                            onChange={(e: any) => {
                                              this.setState({ pass1: e.target.value });
                                            }}

                                            />
                                          }

                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="write_title">비밀번호 확인</td>
                                        <td className="write_basic">
                                          {
                                            this.props && this.props.user && <input className="input1" style={{width:'200px'}} 
                                            
                                              value={this.state.pass2}
                                              onChange={(e: any) => {
                                                this.setState({ pass2: e.target.value });
                                              }}
                                            />
                                          }


                                        </td>
                                      </tr>

                                </tbody></table>
                                
                                <div className="con_box20">
                                  <div className="btn_wrap_center">
                                    <ul>
                                      <li><a onClick={()=> this.handleExchangeToPassword()}><span className="btn3_1">비밀 번호변경</span></a></li>
                                    </ul>
                                  </div>
                                </div>
          
                  
                            </div>                               
                        
                                       
                    </div>
                      
             
                        </div>
                    </div>
                    </div>
        )}
      </Popup>
    );
  }
}
